// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";


@media (min-width: 1025px) {
  .vanilla-json-editor {
    width: 100%;
    height: 700px;
    display: flex;
  }

  .kt-header {
    height: 50px;
  }

  .kt-header--fixed.kt-subheader--fixed .kt-subheader {
    top: 50px;
    height: 35px;
  }

  .kt-subheader--solid .kt-subheader {
    min-height: 35px;
  }

  .kt-header--fixed.kt-subheader--fixed.kt-subheader--enabled .kt-wrapper {
    padding-top: 85px;
  }

  .kt-content {
    padding: 0;
  }

  .kt-fit-height {
		height: 100% !important;
	}

  .kt-fit-height-flex {
    flex: 1 1 0 !important;
    overflow-y: auto;
  }

  .kt-flex-column {
    display: flex;
    flex-direction: column;
  }

  .kt-container {
    padding: 0 10px;
  }

  .kt-footer--fixed .kt-footer {
    height: 40px;
  }

  .kt-aside .kt-aside-menu {
    margin: 5px 0;
  }

  .kt-aside-menu .kt-menu__nav {
    padding: 10px 0;
  }

  .kt-aside--minimize .kt-aside-menu .kt-menu__nav {
    padding: 10px 0;
  }
}

.Toastify__toast--error {
  color: rgb(101, 22, 48) !important;
  background-color: rgb(254, 235, 241) !important;
}

.Toastify__toast-container {
  width: 380px !important;
}

.Toastify__toast-body {
  overflow-wrap: anywhere;
}

.Toastify__close-button {
  align-self: center !important;
}

.highlighted-row-color td {
  background-color: #94c83dbd !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-weight-600 {
  font-weight: 600;
}

.contract-badge-orange {
  background-color: #FFF8DD !important;
  color: #ffad00 !important;
}

.contract-badge-green {
  background-color: #E8FFF3 !important;
  color: #13A86E !important;
}

.contract-badge-blue {
  background-color: #F1FAFF !important;
  color: #4154B2 !important;
}

.contract-badge-red {
  background-color: #fff1f4 !important;
  color: #fd397a !important;
}

.contract-badge-purple {
  background-color: #fbeefd !important;
  color: #9c27b0 !important;
}

.text-underline {
  text-decoration: underline;
}

.warning-progress-bar {
  // background-color: #ffd758 !important;
}

.warning-progress-bar>div {
  background-color: #ffd758 !important;
  color: black;
}

@counter-style suffix-lower-alpha {
  system: extends lower-alpha;
  suffix: ') ';
}

@counter-style suffix-lower-roman {
  system: extends lower-roman;
  suffix: ') ';
}

.lower-alpha {
  list-style: suffix-lower-alpha;
}

.lower-roman {
  list-style: suffix-lower-roman;
}

.lower-alpha>li {
  margin-top: 10px;
}

.lower-roman>li {
  margin-top: 10px;
}

.SortableHelper {
  z-index: 1300;
  background-color: #94c83dbd;
}

.progress-min-width>div {
  min-width: 20%;
}

.overflowx-inherit {
  overflow-x: inherit !important;
}

.datatable tfoot {
  z-index: 0 !important;
  position: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bread-crumb .MuiBreadcrumbs-separator {
  font-size: 18px;
  font-weight: 500;
}

.bread-crumb .breadcrumb-link {
  color: #757575;
  font-size: 14px;
  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.035px;
}

.bread-crumb .breadcrumb-link:hover {
  text-decoration: underline !important;
}

.bread-crumb .breadcrumb-active {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.038px;
}

.kt-portlet .kt-portlet__head {
  min-height: unset;
}

#chartjs-tooltip {
  line-height: normal;
  opacity: 1;
  padding: .3em;
  pointer-events: none;
  position: fixed;
  text-align: center;
  transform: translate(-50%, 0);
  transition: all .1s ease;
}

.custom-tooltip-bg {
  background-color: #333333;
}

#chartjs-tooltip th {
  font-weight: bold !important;
}

.tootlip-hr {
  border-color: white !important;
}


.tooltip-stats-color-0 {
  color: #5578eb !important
}

.tooltip-stats-color-1 {
  color: #5578eb !important
}

.tooltip-stats-color-2 {
  color: #94c83d !important
}

.tooltip-stats-color-3 {
  color: #ffb822 !important
}

.tooltip-stats-color-4 {
  color: #fd397a !important
}

.mt-24 {
  margin-top: 24px;
}

.fee-schedule-rerder-button {
  visibility: hidden !important;
  padding: 2px !important;
}

.fee-schedule-row:hover .fee-schedule-rerder-button {
  visibility: visible !important;
}

.fee-schedule-row .drag-cell,
.fee-schedule-thr .drag-cell {
  width: 50px;
  padding: 0 !important;
}

.aligned-content {
  max-width: 42%;
  display: inline-table
}

#drag-ghost {
  width: 250px;
  height: 40px;
  background-color: #91BD28;
  background: #91BD28;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 111111;
  font-weight: 500;
}
.ellipisis-btn
{
max-width: fit-content;
font-size: 15px;
margin-left: 5px;
}

// creating tree type structure
.tree {
  --spacing: 1.5rem;
  --radius: 10px;
  padding:0;
}

.tree li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}



.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
}

.tree summary {
  display: block;
  cursor: pointer;
}

// creating tree type structure
