.dropzone-grid .MuiChip-label {
    width: 150px;
    color: #fff;
    text-align: left;
}

.dropzone-grid .MuiChip-outlined {
    background-color: #94c83d;
    border: 1px solid #94c83d;
}

.dropzone-grid .MuiChip-deleteIcon {
    color: rgba(255, 255, 255, 0.7);
}

.dropzone-grid .MuiChip-deleteIcon:hover {
    color: #fff
}