.vanilla-jsoneditor-react {
  display: flex;
  flex: 1;
}

.vanilla-json-editor .jse-tree-mode {
  border: 1px solid #ebedf2;
  border-radius: 8px;
}

.vanilla-json-editor .jse-table-mode {
  border: 1px solid #ebedf2;
  border-radius: 8px;
}

.vanilla-json-editor .jse-text-mode {
  border: 1px solid #ebedf2;
  border-radius: 8px;
}


.vanilla-json-editor .jse-menu {
  background-color: #EBFFF5 !important;
  background: #EBFFF5 !important;
  color: #3E4462 !important;
  border: none !important;
  padding: 10px !important;
}

.vanilla-json-editor .jse-menu .jse-button {
  border: none !important;
  border-radius: 6px !important;
  margin-right: 5px !important;
  text-transform: capitalize !important;
  opacity: 0.7 !important;
}

.vanilla-json-editor .jse-menu .jse-selected {
  opacity: 1 !important;
  background-color: #94c83d !important;
  color: #fff !important;
}

.vanilla-json-editor .jse-menu .jse-button:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}

.vanilla-json-editor .jse-menu .jse-selected:hover {
  opacity: 1 !important;
  background-color: #94c83d !important;
  color: #fff !important;
}

.vanilla-json-editor .jse-menu .jse-button:focus {
  background: rgba(0, 0, 0, 0.04) !important;
}

.vanilla-json-editor .jse-contents {
  border: none !important;
}

.vanilla-json-editor .jse-table-mode-welcome.svelte-1te5h1h .jse-nested-arrays button.jse-nested-array-action.svelte-1te5h1h {
  background: #5578eb;
  border-radius: 6px !important;
  opacity: 0.7;
}

.vanilla-json-editor .jse-table-mode-welcome.svelte-1te5h1h .jse-nested-arrays button.jse-nested-array-action.svelte-1te5h1h:hover {
  opacity: 1;
  background: #5578eb;
}

.vanilla-json-editor .jse-header.svelte-d01h2s.svelte-d01h2s {
  background-color: none !important;
  background: none !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 5px 12px !important;
  font-size: 1.25rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.6;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.vanilla-json-editor .jse-modal.svelte-a9bu3a .jse-modal-contents .jse-actions button.jse-primary.svelte-a9bu3a
{
  color: #fff;
  background: #5d78ff;
  background-color: #5d78ff;
}

.vanilla-json-editor .jse-modal.svelte-a9bu3a .jse-modal-contents .jse-actions button.jse-primary.svelte-a9bu3a:hover {
  background: rgb(65, 84, 178);
  background-color: rgb(65, 84, 178);
}

.vanilla-json-editor .jse-modal.svelte-a9bu3a .jse-modal-contents .jse-actions button.jse-primary.svelte-a9bu3a
{
  color: #fff;
  background: #5d78ff;
  background-color: #5d78ff;
}

.vanilla-json-editor .jse-modal.svelte-a9bu3a .jse-modal-contents .jse-actions button.jse-secondary.svelte-a9bu3a:hover {
  background: #e0e0e0;
  background-color: #e0e0e0;
}
.vanilla-json-editor  .jse-sort, .vanilla-json-editor  .jse-transform, .vanilla-json-editor  .jse-contextmenu, .vanilla-json-editor  .jse-undo, .vanilla-json-editor  .jse-redo, .vanilla-json-editor .jse-context-menu-pointer, .vanilla-json-editor .jse-insert-area {
  display: none !important;
}


// .jse-modal-window .jse-header
// {display: none !important;}