.dashboard-page {
    display: flex;
    width: 100%;
    height: calc(100% - 46px);
    flex-direction: column;
    overflow: hidden;

    .dashboard-container {
        display: flex;
        width: 100%;
        height: calc(100% - 3px);
        flex-direction: column;
        overflow: hidden;
    }

    .quicksight-embedding-iframe {
        flex-grow: 1;
        border: none;
        margin: 0;
        padding: 0;
    }
}